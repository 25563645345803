import React from "react";

const PersonalInterest = () => {
  return (
    <div>
      <h1 className="h1interest">My personal interests</h1>
      <p className="interest" id="interest">
        I love coding, biking and my wife i guess i have to add this since she might read
        this. Coding is my everyday thing but when i get stressed and cant figure it out,
        i road-bike from West Valley to Salt lake, either check out the canyon and tire
        myself out. I ebike too when i get bored. I like smelling the wind breeze, and the
        plants it make my stress goes away.
      </p>
    </div>
  );
};

export default PersonalInterest;
