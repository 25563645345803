import React from "react";

const Haiku = () => {
  return (
    <>
      <h1 className="h1aboutme">Haiku</h1>
      <p className="haiku">
        Everyday is a struggle. like building a code all the we encounter an error, we
        always find a solution to fix it. Never seen this type of syntax? let me try it.
        Let me google it. Let me copy this and hope and pray it will work. Life is good.
      </p>
    </>
  );
};

export default Haiku;
